import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import Booking from "../components/Booking.tsx"
import BookingWidget from "../components/BookingWidget.tsx"
import Title from "../components/Title.tsx"
import Sticky from "../components/Sticky.tsx"
import Layout from "../components/layout"
import { isMobile } from "react-device-detect"
import Subnavigation from "../components/Subnavigation.tsx"
import SEO from "../components/Seo.js"

import { BOOKING_CONFIG } from "../constants"

let BACKGROUND_IMAGE = {}
let SUBMENU = []
let mobilecontent

// const stripHtml = (html) => {
//   if (typeof window !== 'undefined') {
//     const doc = new DOMParser().parseFromString(html, 'text/html');
//     return doc.body.textContent || '';
//   }
//   return html;
// };
const PostTemplate = props => {
  const {
    data: { wordpressWpCareers: post, submenu },
  } = props

  if (isMobile) {
    var mobilecontent = post.content.replace(
      'class="content ',
      'class="content mobile '
    )
  } else {
    var mobilecontent = post.content
  }

  return (
    <Layout>
      <Helmet
        title={post.yoast.title}
        meta={[
          {
            name: "description",
            content: post.yoast.metadesc,
          },
        ]}
        link={[{ rel: "canonical", content: post.yoast.canonical }]}
      />
      <SEO
        title={post.yoast.title || post.acf.title_seo || post.title}
        description={
          post.yoast.metadesc || post.acf.paragraph_seo || post.excerpt
        }
        pathname={post.slug}
        article
      />

      {post.acf.two_collums == "yes" && (
        <Sticky backgroundImage="https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2016/12/07-EntranceLobby-new.jpg">
          {submenu.edges.map(({ node }, i) => {
            SUBMENU = node.items
          })}
          <Subnavigation items={SUBMENU} />
          <Title
            title={post.acf.title_seo ? post.acf.title_seo : post.title}
            subtitle={post.acf.subtitle_seo}
            paragraphs={post.acf.paragraph_seo}
          />
          <div className="post">
            {" "}
            <div
              className="careers"
              dangerouslySetInnerHTML={{ __html: mobilecontent }}
            />{" "}
          </div>
        </Sticky>
      )}

      {post.acf.two_collums == "no" && (
        <div className="sticky">
          <Title
            title={post.acf.title_seo ? post.acf.title_seo : post.title}
            subtitle={post.acf.subtitle_seo}
            paragraphs={post.acf.paragraph_seo}
          />
          <div className="post">
            {" "}
            <div dangerouslySetInnerHTML={{ __html: mobilecontent }} />{" "}
          </div>
        </div>
      )}
    </Layout>
  )
}
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
}
export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    submenu: allWordpressWpApiMenusMenusItems(
      filter: { slug: { eq: "about" } }
    ) {
      edges {
        node {
          items {
            title
            url
            target
          }
        }
      }
    }
    wordpressWpCareers(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "DD, MMM YYYY")
      slug
      yoast {
        title
        metadesc
        canonical
      }
      acf {
        title_seo
        subtitle_seo
        paragraph_seo
        two_collums
      }
    }
  }
`
